<template>
  <div class="segmentationFormHeaderContainer" v-if="ready">
    <v-row>
      <v-col sm="12" md="6">
        <pre :class="dairyIsInactiveClass(entireSegmentationFormObject.inactiveBy, entireSegmentationFormObject.validUntil)">{{
          entireSegmentationFormObject.dairyAddress
        }}</pre>
        <br />
      </v-col>

      <v-col sm="12" md="6">
        <v-row>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_valid_until')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0">
            {{ $formatChDate(entireSegmentationFormObject.validFrom) }}&nbsp;-&nbsp;{{ $formatChDate(entireSegmentationFormObject.validUntil) }}<br
          /></v-col>
          <!-- TODO <span v-html="$t('segmentation_forms_correction')" /><br /> -->
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_code')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ entireSegmentationFormObject.code }}<br /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_report_id')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ entireSegmentationFormObject.id }}<br /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_mbhident')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ entireSegmentationFormObject.dairyIdent }}<br /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_petitioner_phone')" /> </v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ entireSegmentationFormObject.dairyPhone1 }}<br /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_betriebsform')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ entireSegmentationFormObject['dairyType' + this.$getUpLangKey()] }}<br /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"><span v-html="$t('segmentation_forms_sachbearbeitertsm')" /></v-col>
          <v-col sm="12" lg="6" class="mt-0 pt-0 mb-0 pb-0"> {{ translateResponsabilityGroup(entireSegmentationFormObject.responsibilityGroup) }}<br /> </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { useResponsibilityGroupsStore } from '@/store/enums/ResponsibilityGroupsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  props: ['entireSegmentationFormObject'],
  computed: {
    responsibilityGroups() {
      return useResponsibilityGroupsStore().responsibilityGroups
    },
    ready() {
      return this.responsibilityGroups && this.responsibilityGroups.length > 0 && this.entireSegmentationFormObject.responsibilityGroup
    }
  },
  methods: {
    translateResponsabilityGroup(rg) {
      let groups = this.responsibilityGroups.filter((el) => el.name == rg)
      return groups[0][this.$getLangKey()]
    },
    dairyIsInactiveClass(inactiveBy, validUntil) {
      if (inactiveBy == null || validUntil == null || inactiveBy == undefined || validUntil == undefined) {
        return false
      }
      const inactiveByDate = new Date(inactiveBy)
      const validUntilDate = new Date(validUntil)
      if (inactiveByDate.valueOf() <= validUntilDate.valueOf()) {
        return 'inactive-dairy'
      } else {
        return ''
      }
    }
  },
  beforeMount() {
    useResponsibilityGroupsStore.fetchResponsibilityGroups()
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

.segmentationFormHeaderContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.bootstrap span {
  font-size: 1em !important;
}

.inactive-dairy {
  color: $dbm-inactive-red;
}
</style>
