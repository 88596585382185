<template>
  <v-container>
    <v-layout row justify-center>
      <v-overlay v-model="loading" persistent fullscreen id="loading-dialog" content-class="loading-dialog">
        <v-container fill-height>
          <v-layout row justify-center align-center>
            <v-progress-circular indeterminate color="secondary"></v-progress-circular>
          </v-layout>
        </v-container>
      </v-overlay>
    </v-layout>
    <v-form ref="segmentationForms" name="segmentationForms" @submit.prevent="onSubmit()" class="form form--login grid" autocomplete="off">
      <v-row>
        <v-col cols="12"><h1 v-html="$t('segmentation_forms_edit_title')"></h1></v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <div v-if="segmentationForm">
            <SegmentationFormHeader :entireSegmentationFormObject="segmentationForm" />
          </div>
        </v-col>
        <v-col cols="4">
          <v-row>
            <v-col cols="8">
              <v-select
                id="search_segmentation_forms_status"
                :loading="loadingCorrection"
                v-model="segmentationForm.status"
                :items="segmentationStatusesCorrection"
                :item-title="this.$getLangKey()"
                item-value="name"
                :readonly="isReadOnly()"
                @update:modelValue="correction()"
                solo
                class="custom-select"
              >
                <template v-slot:item="{ props }">
                  <v-list-item v-bind="props" :disabled="checkIsItCorrection(props.value)"></v-list-item>
                </template> </v-select
              ><!-- :item-disabled="checkIsItCorrection"-->
            </v-col>
            <v-col cols="4">
              <v-btn
                color="primary"
                class="shadow-none"
                :to="{
                  name: 'processingForms_edit',
                  params: { id: this.segmentationForm.processingFormId }
                }"
                ><span v-html="$t('segmentation_form_direct_to_tsm')"
              /></v-btn>
            </v-col>
          </v-row>
          <v-row
            ><div v-if="!showVersion()" class="align-version">
              <span v-html="$t('segmentation_form_version_number')" />:
              {{ segmentationForm.versionNo }}
            </div>
          </v-row>
          <v-row>
            <v-col className="item">
              <rdhComponent
                :show="$privileges.has({ path: '/masterData', permission: 'showRemarksDocumentsHistory' })"
                :remarks_show="true"
                :remarks_tag="'segmentations_' + $route.params.id"
                :documents_show="true"
                :documents_tag="'segmentations_' + $route.params.id"
                :history_show="true"
                :history_url="'/segmentationForms/' + $route.params.id"
                ref="rdh"
              ></rdhComponent>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <div>
            <SegmentationFormPreview
              :segmentationFormId="$route.params.id"
              :status="segmentationForm.status"
              ref="segmentationFormPreview"
              :endOfReportingPeriod="segmentationForm.validUntil"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div class="aligned-buttons">
          <v-btn color="secondary" class="shadow-none" :to="{ name: 'segmentationForms_read' }"><span v-html="$t('back')" /></v-btn>
          <v-btn color="secondary" class="shadow-none" @click="cancel()"><span v-html="$t('cancel')" /></v-btn>
          <span class="margin-sides">
            <v-menu class="m-2" offset-y>
              <template v-slot:activator="{ props }">
                <v-btn id="formular" color="secondary" v-bind="props">
                  <span v-html="$t('segmentation_form_form')" />
                  <v-icon dbmblueprimary right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportCsv" id="export_btn" key="segmentation_form_export">
                  <v-list-item-title><span v-html="$t('segmentation_form_xlsx_export_import')" /></v-list-item-title>
                </v-list-item>
                <v-list-item id="print_btn" key="segmentation_form_print" @click="print">
                  <v-list-item-title><span v-html="$t('segmentation_form_print')" /></v-list-item-title>
                </v-list-item>
                <v-list-item
                  id="segmentation_relation_btn"
                  key="segmentation_form_segmentation_relation"
                  :to="{
                    name: 'segmentationRelations_read',
                    query: {
                      size: 10,
                      term: 'Dairy_ident:' + this.segmentationForm.dairyIdent,
                      relationDate: this.segmentationForm.validUntil
                    }
                  }"
                >
                  <v-list-item-title><span v-html="$t('segmentation_form_segmentation_relation')" /></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </span>
          <v-btn color="primary" class="shadow-none" type="submit"><span v-html="$t('continue')" /></v-btn>
        </div>
      </v-row>
    </v-form>
  </v-container>
</template>
<script lang="ts">
import { apiURL, emitter } from '@/main'
import { fileCreator, showError } from '@/services/axios'
import SegmentationFormHeader from '@/views/segmentationForms/components/segmentationFormHeader.vue'
import SegmentationFormPreview from '@/views/segmentationForms/segmentationFormPreview.vue'

import rdhComponent from '@/components/rdh/rdhComponent'
import { useSegmentationStatusStore } from '@/store/enums/SegmentationStatusStore'

export const segmentationFormMinimal = {
  id: null,
  validFrom: '2021-01-01',
  code: null,
  status: null,
  legalEntity: {
    id: null,
    locality: null,
    zip: null,
    name1: null,
    name2: null,
    uid: null,
    attention: null,
    phone1: null
  },
  validUntil: '2021-01-31',
  dairyId: null,
  dairyIdent: null,
  statusNameFr: null,
  statusNameDe: null,
  statusNameIt: null,
  dairyPersonInitials: null
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationForms_edit',
  props: {},
  data() {
    return {
      segmentationForm: segmentationFormMinimal,
      loading: false,
      loadingCorrection: false
    }
  },
  computed: {
    segmentationStatusesCorrection() {
      return useSegmentationStatusStore().segmentationStatusesCorrection
    }
  },
  components: {
    SegmentationFormHeader: SegmentationFormHeader,
    SegmentationFormPreview: SegmentationFormPreview,
    rdhComponent: rdhComponent
  },
  methods: {
    async correction() {
      this.loadingCorrection = true
      try {
        const result = await this.axios.patch(
          apiURL + '/segmentationForms/' + this.$route.params.id + '/correction',
          {
            status: 'CORRECTION'
          },
          { headers: { 'Content-Type': 'application/json' } }
        )

        emitter.emit('toastSuccess', {
          message: this.$t('segmentation_form_correction_success')
        })
        this.loadSegmentationFormHeader()
      } catch (e) {
        showError(e)
      } finally {
        this.loadingCorrection = false
      }
    },
    isReadOnly() {
      if (this.segmentationForm.status == 'CLOSED' || this.segmentationForm.status == 'DIFFERENCE') {
        return false
      } else return true
    },
    checkIsItCorrection(value: string) {
      return value === 'CORRECTION' ? false : true
    },

    showVersion() {
      if (this.segmentationForm.versionNo > 1) {
        return true
      } else return false
    },
    async loadSegmentationFormHeader() {
      try {
        const result = await this.axios.get(apiURL + '/segmentationForms/' + this.$route.params.id, { headers: { 'Content-Type': 'application/json' } })
        if (await result) {
          this.segmentationForm = result.data
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async onSubmit() {
      this.$router
        .push({
          name: 'segmentationForms_edit_step_one',
          params: { id: this.$route.params.id }
        })
        .catch((err) => {
          // duplicated route
        })
    },
    async print() {
      try {
        const result = await this.axios.get('/api/segmentationForms/' + this.$route.params.id + '/reportBOM1', {
          headers: { Accept: 'application/pdf' },
          responseType: 'blob',
          params: { segmentationFormId: this.$route.params.id }
        })
        fileCreator(await result)
      } catch (e) {
        showError(e)
      }
    },
    exportCsv() {
      this.$refs.segmentationFormPreview.openDialog()
    },
    cancel() {
      this.$router
        .push({
          name: 'segmentationForms_read'
        })
        .catch((err) => {
          // duplicated route
        })
    }
  },
  beforeMount() {
    useSegmentationStatusStore().fetchSegmentationStatuses()
    this.loadSegmentationFormHeader()
  }
})
</script>
<style lang="scss" scoped>
.margin-sides {
  margin-left: 1em;
  margin-right: 1em;
}

.align-version {
  flex-direction: row;
}

.custom-select .v-select__slot {
  background-color: white;
}
</style>
