<template>
  <v-container>
    <v-row>
      <!-- Milcheinkauf direkt von Produzenten -->
      <v-col sm="12" md="12"> {{ purchaseFarm['delivery' + this.$getUpLangKey()] }} <br /></v-col>
      <div class="v-data-table min-width theme--light custom-table-styling">
        <div class="v-table__wrapper">
          <table class="table table-sm" :loading="tableLoading">
            <thead>
              <tr>
                <th :width="farmFields[0].width">
                  <span v-html="$t('segmentation_forms_preview_id')" />
                </th>
                <th :width="farmFields[1].width">
                  <span v-html="$t('segmentation_forms_preview_segmentation_type')" />
                </th>
                <th :width="farmFields[2].width">
                  <span v-html="$t('segmentation_forms_preview_feeding')" />
                </th>
                <th :width="farmFields[3].width">
                  <span />
                </th>
                <th :width="farmFields[4].width">
                  <span />
                </th>
                <th :width="farmFields[5].width">
                  <span />
                </th>
                <th :width="farmFields[6].width">
                  <span />
                </th>
                <th :width="farmFields[7].width">
                  <span />
                </th>
                <th :width="farmFields[8].width">
                  <span />
                </th>
                <th :width="farmFields[9].width" align="right">
                  <span v-html="$t('segmentation_forms_preview_quantity')" />
                </th>
                <th :width="farmFields[10].width" align="right">
                  <span v-html="$t('segmentation_forms_preview_difference_tsm_1')" />
                </th>
                <th :width="farmFields[11].width">
                  <span v-html="$t('segmentation_forms_preview_status_tsm_1')" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pfItem, pfIndex) of purchaseFarmItems" :key="pfItem.id">
                <!-- Pos-Nr. -->
                <td>
                  {{ pfItem.posNo }}
                </td>
                <!-- Segment -->
                <td>
                  {{ pfItem['segmentationType' + this.$getUpLangKey()] }}
                </td>
                <!-- Fütterung -->
                <td>
                  {{ pfItem['feeding' + this.$getUpLangKey()] }}
                </td>
                <!-- Ident Placeholder -->
                <td></td>
                <!-- AddressName1 Placeholder -->
                <td></td>
                <!-- addressName2 Placeholder -->
                <td></td>
                <!-- addressLine1 Placeholder -->
                <td></td>
                <!-- addressZip Placeholder -->
                <td></td>
                <!-- addressLocality Placeholder -->
                <td></td>
                <!-- Menge kg -->
                <td>
                  <vNumberField
                    :name="'quantity_' + pfItem.id"
                    v-model="pfItem.quantity"
                    filled
                    class="right-aligned-input nomessage"
                    @change="updateRow(pfItem, 0)"
                    density="compact"
                    :id="'quantity_' + pfItem.id"
                    :precision="0"
                    @keydown.enter.prevent="updateRow(pfItem, 0)"
                    hide-details
                    :loading="pfItem.loading"
                    @keyup.enter="$goNext($event.target)"
                    :disabled="isDisabled()"
                  ></vNumberField>
                </td>
                <!-- Differenz zu TSM1 -->
                <td :rowspan="numberOfNoSilo" v-if="pfIndex == 0" align="right">
                  <span class="dbm-number"> {{ noSilageDifference }} </span>
                </td>
                <td :rowspan="numberOfSilo" v-if="pfIndex == numberOfNoSilo" align="right">
                  <span class="dbm-number"> {{ silageDifference }} </span>
                </td>
                <td :rowspan="numberOfNoSilo" v-if="pfIndex == 0">
                  {{ purchaseFarm['processingFormStatus' + this.$getUpLangKey()] ? purchaseFarm['processingFormStatus' + this.$getUpLangKey()] : $t('none') }}
                </td>
                <td :rowspan="numberOfSilo" v-if="pfIndex == numberOfNoSilo">
                  {{ purchaseFarm['processingFormStatus' + this.$getUpLangKey()] ? purchaseFarm['processingFormStatus' + this.$getUpLangKey()] : $t('none') }}
                </td>
              </tr>
            </tbody>
            <!-- Footer -->
            <tfoot>
              <tr>
                <td :width="farmFields[0].width">
                  <span />
                </td>
                <td :width="farmFields[1].width">
                  <span />
                </td>
                <td :width="farmFields[2].width">
                  <span />
                </td>
                <td :width="farmFields[3].width">
                  <span />
                </td>
                <td :width="farmFields[4].width">
                  <span />
                </td>
                <td :width="farmFields[5].width">
                  <span />
                </td>
                <td :width="farmFields[6].width">
                  <span />
                </td>
                <td :width="farmFields[7].width">
                  <span />
                </td>
                <td :width="farmFields[8].width"></td>
                <td :width="farmFields[9].width" class="text-end">
                  <span v-html="$t('Total')" />:
                  <span class="dbm-number">{{ $formatNumber(purchaseFarmTotal, 0) }}</span>
                </td>
                <td :width="farmFields[10].width">
                  <span />
                </td>
                <td :width="farmFields[11].width">
                  <span />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <!-- Milchzukauf -->
      <v-col sm="12" md="12"> {{ purchaseDairy['delivery' + this.$getUpLangKey()] }}<br /></v-col>
      <v-data-table-virtual
        :headers="dairyFields"
        :items="purchaseDairyItems"
        :fields="dairyFields"
        :loading="tableLoading"
        hide-default-footer
        disable-pagination
        class="min-width custom-table-styling"
      >
        <!-- MBH-ID -->
        <template v-slot:[`item.dairy.ident`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.ident }}
          </span>
        </template>
        <!-- Name/Firma -->
        <template v-slot:[`item.dairy.addressName1`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressName1 }}
          </span>
        </template>
        <!-- Vorname/Zusatz -->
        <template v-slot:[`item.dairy.addressName2`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressName2 }}
          </span>
        </template>
        <!-- Strasse -->
        <template v-slot:[`item.dairy.addressLine1`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressLine1 }}
          </span>
        </template>
        <!-- PLZ -->
        <template v-slot:[`item.dairy.addressZip`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressZip }}
          </span>
        </template>
        <!-- Ort -->
        <template v-slot:[`item.dairy.addressLocality`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressLocality }}
          </span>
        </template>
        <!-- Differenz zu TSM1 -->
        <template v-slot:[`item.differenceSellingQuantity`]="{ item }">
          <span class="dbm-number" v-if="showDifference(item)">
            {{ $formatNumber(calculateDifferenceSellingQuantity(item)) }}
          </span>
        </template>
        <!-- Menge in kg -->
        <template v-slot:[`item.quantity`]="{ item, index }">
          <vNumberField
            :name="'quantity_' + item.id"
            v-model="item.quantity"
            filled
            class="right-aligned-input nomessage"
            @keyup.enter="$goNext($event.target)"
            :disabled="isDisabled()"
            @change="updateRow(item, 1)"
            density="compact"
            :id="'quantity_' + item.id"
            :precision="0"
            @keydown.enter.prevent="updateRow(item, 1)"
            hide-details
            :loading="item.loading"
          ></vNumberField>
        </template>
        <template v-slot:[`item.counterpartStatus`]="{ item }">
          {{ item['counterpartStatus' + this.$getUpLangKey()] ? item['counterpartStatus' + this.$getUpLangKey()] : $t('none') }}
        </template>
        <!-- Footer -->
        <template v-slot:[`body.append`]="{ headers }">
          <tr>
            <td v-for="(header, i) in headers[0]" :key="i">
              <div v-if="header.value === 'quantity'" class="text-end">
                <span v-html="$t('Total')" />:<span class="dbm-number"> {{ $formatNumber(purchaseDairyTotal, 0) }}</span>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table-virtual>

      <!-- Milchablieferung -->
      <v-col sm="12" md="12"> {{ sellingDairy['delivery' + this.$getUpLangKey()] }}<br /></v-col>
      <v-data-table-virtual
        :headers="dairyFields"
        :items="sellingDairyItems"
        :fields="dairyFields"
        :loading="tableLoading"
        hide-default-footer
        disable-pagination
        class="min-width custom-table-styling"
      >
        <!-- MBH-ID -->
        <template v-slot:[`item.dairy.ident`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.ident }}
          </span>
        </template>
        <!-- Name/Firma -->
        <template v-slot:[`item.dairy.addressName1`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressName1 }}
          </span>
        </template>
        <!-- Vorname/Zusatz -->
        <template v-slot:[`item.dairy.addressName2`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressName2 }}
          </span>
        </template>
        <!-- Strasse -->
        <template v-slot:[`item.dairy.addressLine1`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressLine1 }}
          </span>
        </template>
        <!-- PLZ -->
        <template v-slot:[`item.dairy.addressZip`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressZip }}
          </span>
        </template>
        <!-- Ort -->
        <template v-slot:[`item.dairy.addressLocality`]="{ item }">
          <span :class="dairyIsInactiveClass(item.dairyInactiveBy)">
            {{ item.dairy.addressLocality }}
          </span>
        </template>
        <!-- Differenz zu TSM1 -->
        <template v-slot:[`item.differenceSellingQuantity`]="{ item }">
          <span class="dbm-number" v-if="showDifference(item)">
            {{ $formatNumber(calculateDifferenceSellingQuantity(item)) }}
          </span>
        </template>
        <!-- Menge in kg -->
        <template v-slot:[`item.quantity`]="{ item }">
          <vNumberField
            :name="'quantity_' + item.id"
            v-model="item.quantity"
            filled
            class="right-aligned-input nomessage"
            @keyup.enter="$goNext($event.target)"
            :disabled="isDisabled()"
            @change="updateRow(item, 2)"
            density="compact"
            :id="'quantity_' + item.id"
            :precision="0"
            @keydown.enter.prevent="updateRow(item, 2)"
            hide-details
            :loading="item.loading"
          ></vNumberField>
        </template>
        <template v-slot:[`item.counterpartStatus`]="{ item }">
          {{ item['counterpartStatus' + this.$getUpLangKey()] ? item['counterpartStatus' + this.$getUpLangKey()] : $t('none') }}
        </template>
        <!-- Footer -->
        <template v-slot:[`body.append`]="{ headers }">
          <tr>
            <td v-for="(header, i) in headers[0]" :key="i">
              <div v-if="header.value === 'quantity'" class="text-end">
                <span v-html="$t('Total')" />:<span class="dbm-number"> {{ $formatNumber(sellingDairyTotal, 0) }}</span>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table-virtual>
    </v-row>
    <v-dialog v-model="dialog" max-width="640">
      <v-card class="custom-horizontal-scroll-style">
        <v-card-title v-html="$t('segmentation_form_show_xlsx_rows')"></v-card-title>
        <v-row>
          <v-col cols="6">
            <v-card-text>
              <v-form ref="fileuploader" @submit.prevent="importFile()">
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-file-input
                        ref="xlsx_upload"
                        v-model="selectedFile"
                        :rules="[$rules.required]"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        truncate-length="15"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-btn :loading="xlsxRows.rowsLeft.length > 0" :disabled="xlsxRows.rowsLeft.length > 0" type="submit">
                        <br />
                        <span v-html="$t('segmentation_form_import_xlsx')"></span
                      ></v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
          </v-col>

          <v-col cols="6">
            <v-card-text class="custom-vertical-scroll-style">
              <template v-if="inProgress">
                <h4 v-html="$t('segmentation_form_rows_found')"></h4>
                <template v-for="row of xlsxRows.rowsFoundInXlsx" :key="row.dialogueId">
                  <v-list-item>
                    <v-list-item-title>{{ row.posNo }}/{{ row['feeding' + $route.params.upLangKey] }}/{{ row.dairy.ident }} </v-list-item-title>
                  </v-list-item>
                </template>

                <h4 v-html="$t('segmentation_form_rows_not_found')"></h4>
                <template v-for="xlsrow of xlsxRows.rowsOnlyInXlsx" :key="'xlsx_rows' + xlsrow.dialogueId">
                  <v-card :id="'xlsx_rows' + xlsrow.dialogueId" class="mx-auto" overflow max-height="400" max-width="400" tile>
                    <v-list-item>
                      <v-list-item-title>{{ xlsrow.code }}/{{ xlsrow.posNo }}/{{ xlsrow.feeding }}/{{ xlsrow.quantity }}kg </v-list-item-title>
                    </v-list-item>
                  </v-card>
                </template>
              </template>
            </v-card-text>
          </v-col>
        </v-row>

        <v-card-actions>
          <v-btn
            :loading="xlsxRows.rowsLeft.length > 0"
            :disabled="xlsxRows.rowsLeft.length > 0"
            color="primary"
            class="shadow-none"
            @click="closeDialogue()"
            v-html="$t('close')"
          ></v-btn>
          <v-btn class="m-2" color="secondary" @click="exportTableToCSV" v-html="$t('segmentation_form_download_xlsx')"></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { apiURL } from '@/main'
import _ from 'lodash'
import { showError } from '@/services/axios'
import vNumberField from '../../components/v-number-field.vue'
import * as XLSX from 'xlsx/xlsx.mjs'
import { DTSHeader } from '@/services/BackendService'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'segmentationFormPreview',
  props: ['segmentationFormId', 'status', 'endOfReportingPeriod'],
  components: {
    vNumberField: vNumberField
  },
  data() {
    return {
      xlsxRows: {
        rowsLeft: [],
        rowsFoundInXlsx: [],
        rowsOnlyInXlsx: []
      },
      purchaseFarm: {},
      purchaseFarmItems: [],
      purchaseDairy: {},
      purchaseDairyItems: [],
      sellingDairy: {},
      sellingDairyItems: [],
      processingQuantityNoSilage: null,
      processingQuantitySilage: null,
      silageQuantity: 0,
      noSilageQuantity: 0,
      numberOfSilo: 0,
      numberOfNoSilo: 0,
      dialog: false,
      tableLoading: false,
      selectedFile: null,
      farmFields: <DTSHeader[]>[
        // Pos-nr
        {
          title: this.$t('segmentation_forms_preview_id'),
          key: 'posNo',
          sortable: false,
          width: '5%',
          align: 'left'
        },
        // Segment
        {
          title: this.$t('segmentation_forms_preview_segmentation_type'),
          key: 'segmentationType' + this.$getUpLangKey(),
          sortable: false,
          width: '5%'
        },
        // Fütterung
        {
          title: this.$t('segmentation_forms_preview_feeding'),
          key: 'feeding' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        },
        // Placeholder MBH-ID
        {
          title: '',
          key: '',
          sortable: false,
          width: '5%'
        },
        // Placeholder Name/Firma
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder Vorname/Zusatz
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder Strasse
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Placeholder PLZ
        {
          title: '',
          key: '',
          sortable: false,
          width: '5%'
        },
        // Placeholder Ort
        {
          title: '',
          key: '',
          sortable: false,
          width: '10%'
        },
        // Menge in kg
        {
          title: this.$t('segmentation_forms_preview_quantity'),
          key: 'quantity',
          sortable: false,
          width: '10%'
        },
        // Differenz zu TSM 1
        {
          title: this.$t('segmentation_forms_preview_difference_tsm_1'),
          key: '',
          sortable: false,
          width: '10%'
        },
        // Status von TSM 1
        {
          title: 'TODO ' + this.$t('segmentation_forms_preview_status_tsm_1'),
          key: 'processingFormStatus' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        }
      ],
      dairyFields: <DTSHeader[]>[
        // Pos-Nr.
        {
          title: this.$t('segmentation_forms_preview_id'),
          key: 'posNo',
          sortable: false,
          width: '5%',
          align: 'left'
        },
        // Segment
        {
          title: this.$t('segmentation_forms_preview_segmentation_type'),
          key: 'segmentationType' + this.$getUpLangKey(),
          sortable: false,
          width: '5%'
        },
        // Fütterung
        {
          title: this.$t('segmentation_forms_preview_feeding'),
          key: 'feeding' + this.$getUpLangKey(),
          sortable: false,
          width: '10%'
        },
        // MBH-ID
        {
          title: this.$t('segmentation_forms_preview_mbhident'),
          key: 'dairy.ident',
          sortable: false,
          width: '5%'
        },
        // Name/Firma
        {
          title: this.$t('segmentation_forms_preview_name_company'),
          key: 'dairy.addressName1',
          sortable: false,
          width: '10%'
        },
        // Vorname/Zusatz
        {
          title: this.$t('segmentation_forms_preview_addition'),
          key: 'dairy.addressName2',
          sortable: false,
          width: '10%'
        },
        // Strasse
        {
          title: this.$t('segmentation_forms_preview_street'),
          key: 'dairy.addressLine1',
          sortable: false,
          width: '10%'
        },
        // PLZ
        {
          title: this.$t('segmentation_forms_preview_zip'),
          key: 'dairy.addressZip',
          sortable: false,
          width: '5%'
        },
        // Ort
        {
          title: this.$t('segmentation_forms_preview_city'),
          key: 'dairy.addressLocality',
          sortable: false,
          width: '10%'
        },
        // Menge in kg
        {
          title: this.$t('segmentation_forms_preview_quantity'),
          key: 'quantity',
          sortable: false,
          width: '10%',
          align: 'end'
        },
        // Differenz zu TSM 1
        {
          title: this.$t('segmentation_forms_preview_difference_business_partner'),
          key: 'differenceSellingQuantity',
          sortable: false,
          width: '10%',
          align: 'end'
        },
        // Status von Gegenüger
        {
          title: this.$t('segmentation_forms_preview_status_business_partner'),
          key: 'counterpartStatus',
          sortable: false,
          width: '10%'
        }
      ]
    }
  },
  computed: {
    noSilageDifference() {
      this.noSilageQuantity = 0
      this.purchaseFarmItems
        .filter((item) => item.feeding == 'NO_SILAGE')
        .forEach((element) => {
          if (element.quantity) {
            this.noSilageQuantity += parseInt(element.quantity)
          }
        })
      return this.$formatNumber(this.noSilageQuantity - this.processingQuantityNoSilage)
    },
    silageDifference() {
      this.silageQuantity = 0
      this.purchaseFarmItems
        .filter((item) => item.feeding == 'SILAGE')
        .forEach((element) => {
          if (element.quantity) {
            this.silageQuantity += parseInt(element.quantity)
          }
        })
      return this.$formatNumber(this.silageQuantity - this.processingQuantitySilage)
    },
    purchaseFarmTotal() {
      let total = 0
      this.purchaseFarmItems.forEach((element) => {
        if (element.quantity != null) {
          if (typeof element.quantity == 'string') {
            total = total + parseInt(element.quantity)
          } else {
            total = total + element.quantity
          }
        }
      })
      return total
    },
    purchaseDairyTotal() {
      let total = 0
      this.purchaseDairyItems.forEach((element) => {
        if (element.quantity != null) {
          if (typeof element.quantity == 'string') {
            total = total + parseInt(element.quantity)
          } else {
            total = total + element.quantity
          }
        }
      })
      return total
    },
    sellingDairyTotal() {
      let total = 0
      this.sellingDairyItems.forEach((element) => {
        if (element.quantity != null) {
          if (typeof element.quantity == 'string') {
            total = total + parseInt(element.quantity)
          } else {
            total = total + element.quantity
          }
        }
      })
      return total
    },
    inProgress() {
      return this.xlsxRows.rowsLeft.length + this.xlsxRows.rowsFoundInXlsx.length + this.xlsxRows.rowsOnlyInXlsx.length > 0
    }
  },
  methods: {
    isDisabled() {
      // Form disabled for status = CLOSED or status = DIFFERENCE
      if (this.status == 'CLOSED' || this.status == 'DIFFERENCE') return true
      else return false
    },
    dairyIsInactiveClass(inactiveBy) {
      if (inactiveBy == null || this.endOfReportingPeriod == null || inactiveBy == undefined || this.endOfReportingPeriod == undefined) {
        return false
      }
      const inactiveByDate = new Date(inactiveBy)
      const validUntilDate = new Date(this.endOfReportingPeriod)
      if (inactiveByDate.valueOf() <= validUntilDate.valueOf()) {
        return 'inactive-dairy'
      } else {
        return ''
      }
    },
    async loadSegmentationFormPreview() {
      this.tableLoading = true
      try {
        const result = await this.axios.get(apiURL + '/segmentationForms/' + this.segmentationFormId + '/groupedRows', {
          headers: { 'Content-Type': 'application/json' }
        })
        if (await result) {
          this.groupedRows = result.data
          this.purchaseFarm = this.groupedRows[0]
          this.purchaseFarmItems = this.groupedRows[0].rows.sort((element1, element2) => {
            if (element1.feeding.length === element2.feeding.length) return 0
            if (element1.feeding.length > element2.feeding.length) return -1
            if (element1.feeding.length < element2.feeding.length) return 1
          })
          this.numberOfNoSilo = this.purchaseFarmItems.filter((item) => item.feeding == 'NO_SILAGE').length
          this.numberOfSilo = this.purchaseFarmItems.filter((item) => item.feeding == 'SILAGE').length
          this.noSilageQuantity = 0
          this.purchaseFarmItems
            .filter((item) => item.feeding == 'NO_SILAGE')
            .forEach((element) => {
              this.noSilageQuantity += element.quantity
            })
          this.silageQuantity = 0
          this.purchaseFarmItems
            .filter((item) => item.feeding == 'SILAGE')
            .forEach((element) => {
              this.silageQuantity += element.quantity
            })
          this.processingQuantityNoSilage = this.groupedRows[0].processingQuantityNoSilage
          this.processingQuantitySilage = this.groupedRows[0].processingQuantitySilage
          this.purchaseDairy = this.groupedRows[1]
          this.purchaseDairyItems = this.groupedRows[1].rows
          this.sellingDairy = this.groupedRows[2]
          this.sellingDairyItems = this.groupedRows[2].rows
        }
      } catch (e) {
        showError(e)
      } finally {
        this.tableLoading = false
      }
    },
    async updateRow(item, groupId) {
      item.loading = true
      try {
        const result = await this.axios.patch(
          apiURL + '/segmentationFormRows/' + item.id,
          {
            quantity: parseFloat(parseFloat(item.quantity).toFixed(2))
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        var rowIndex = this.groupedRows[groupId].rows.findIndex((x) => x.id === item.id)
        this.groupedRows[groupId].rows.splice(rowIndex, 1, await result.data)
      } catch (e) {
        showError(e)
      } finally {
        item.loading = false
      }
    },
    calculateDifferenceSellingQuantity(item) {
      if (item.dairyHasReportingObligation === false) return 0 // when dairy doesen report oblications the difference will allways be 0
      if (item.counterpartQuantity == null && item.quantity == null) return ''
      if (item.quantity == null) return 0 - item.counterpartQuantity
      if (item.counterpartQuantity == null) return item.quantity - 0
      return item.quantity - item.counterpartQuantity
    },
    exportTableToCSV() {
      // https://docs.sheetjs.com/
      var data = this.getDownloadData()
      var workbook = XLSX.utils.book_new()
      var worksheet_name = 'SheetJS'
      var worksheet = XLSX.utils.json_to_sheet(data)

      /* change cell format of quantity (from https://github.com/SheetJS/sheetjs/issues/966) */
      var format = '#,##0' // https://docs.sheetjs.com/docs/csf/features/#number-formats
      var range = { s: { r: 1, c: 7 }, e: { r: data.length, c: 7 } }
      for (var R = range.s.r; R <= range.e.r; ++R) {
        for (var C = range.s.c; C <= range.e.c; ++C) {
          var cell = worksheet[XLSX.utils.encode_cell({ r: R, c: C })]
          cell.z = format
        }
      }
      XLSX.utils.book_append_sheet(workbook, worksheet, worksheet_name)
      XLSX.writeFile(workbook, 'segmentationFormsExport.xlsx')
    },
    openDialog() {
      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsOnlyInXlsx = []
      this.dialog = true
    },
    getDownloadData() {
      let rows = []
      this.groupedRows.forEach((y) => {
        let code = 0
        // assign the right code starting from string
        switch (y.delivery) {
          case 'PURCHASE_FARM':
            code = 1
            break

          case 'PURCHASE_DAIRY':
            code = 2
            break

          case 'SELLING_DAIRY':
            code = 3
            break
        }
        y.rows.forEach((row) => {
          // extra fields for each row that we need for the excel
          row.code = code
          row.dairyIdent = row.dairy.ident
          row.dairyAddressName1 = row.dairy.addressName1
          row.dairyAddressName2 = row.dairy.addressName2

          switch (row.feeding) {
            case 'NO_SILAGE':
              row.feeding = 1
              break

            case 'SILAGE':
              row.feeding = 2
              break
          }
          row = _.pick(row, ['code', 'posNo', 'segmentationType', 'feeding', 'dairyIdent', 'dairyAddressName1', 'dairyAddressName2', 'quantity'])
          rows.push(row)
        })
      })
      return rows
    },
    onButtonClick() {
      this.$refs.xlsx_upload.click()
    },

    startUpdateXLSXRows(xlsxRows) {
      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsOnlyInXlsx = []
      this.xlsxRows.rowsLeft = xlsxRows
      this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
    },
    async importFile() {
      if (!(await this.$refs.fileuploader.validate()).valid) {
        return
      }
      this.xlsxRows.rowsFoundInXlsx = []
      this.xlsxRows.rowsOnlyInXlsx = []
      const file = this.selectedFile
      var reader = new FileReader()
      reader.onload = (e) => {
        var data = e.target.result
        var workbook = XLSX.read(data, {
          type: 'binary'
        })
        workbook.SheetNames.forEach((sheetName) => {
          // Here is your object
          var XLSX_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName])
          //translate old columnnames into new, remove it in 2024
          XLSX_row_object.map((row) => {
            if (row.pos_no) {
              row.posNo = row.pos_no
              delete row.pos_no
            }
            if (row.feedingCode) {
              row.feeding = row.feedingCode
              delete row.feedingCode
            }
            if (row.qty_kg) {
              row.quantity = row.qty_kg
              delete row.qty_kg
            }
          })
          this.startUpdateXLSXRows(XLSX_row_object)
        })
      }
      reader.onerror = (ex) => {
        showError(ex)
      }
      reader.readAsBinaryString(file)
      this.selectedFile = null
      this.$refs.fileuploader.reset()
    },
    getFeedingLabel(code) {
      'SILAGE'
      switch (code) {
        case 2:
          return 'SILAGE'
        case 1:
          return 'NO_SILAGE'
        default:
          throw 'no default case for codelabel:' + code
      }
    },
    getCodeLabel(code) {
      switch (code) {
        case 1:
          return 'PURCHASE_FARM'
        case 2:
          return 'PURCHASE_DAIRY'
        case 3:
          return 'SELLING_DAIRY'
        default:
          throw 'no default case for codelabel:' + code
      }
    },
    /* code, posNo, feeding  build together a natural key */
    findSegmentationFormRowByNaturalKey(xlsxRow) {
      let groupedByCodeRow = this.groupedRows.filter((gr) => gr.delivery == this.getCodeLabel(xlsxRow.code))[0]
      let row = groupedByCodeRow.rows.filter((row) => {
        return xlsxRow.posNo == row.posNo && this.getFeedingLabel(xlsxRow.feeding) == row.feeding
      })[0]
      return row
    },

    async updateOneXlsRow(xlsxRow) {
      if (!xlsxRow) {
        // end of recursion
        //this.load()
        this.loadSegmentationFormPreview()
        return
      }

      let row = this.findSegmentationFormRowByNaturalKey(xlsxRow)

      if (!row || row.length == 0) {
        this.xlsxRows.rowsOnlyInXlsx.push(xlsxRow)
        this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
        return
      }

      row.quantity = xlsxRow.quantity
      try {
        const result = await this.axios.patch(
          apiURL + '/segmentationFormRows/' + row.id,
          {
            quantity: parseFloat(parseFloat(row.quantity).toFixed(2))
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          this.xlsxRows.rowsFoundInXlsx.push(row)
          this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
          return
        }
      } catch (e) {
        showError(e)
        this.xlsxRows.rowsOnlyInXlsx.push(row)
        this.updateOneXlsRow(this.xlsxRows.rowsLeft.pop())
        return
      }
    },
    closeDialogue() {
      this.dialog = false
      this.xlsxRows.rowsOnlyInXlsx = []
      this.xlsxRows.rowsFoundInXlsx = []
    },
    showDifference(item) {
      if (item.counterpartStatus == 'CREATED' || item.counterpartStatus == null) return false
      if (!item.quantity && !item.counterpartQuantity) return false
      return true
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.loadSegmentationFormPreview()
    })
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';

// To allign columns in tables
// td,
// th {
//   word-wrap: break-word !important;
//   word-break: break-all !important;
// }

.min-width {
  min-width: 1200px !important;
}

// abgeschlossen
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-input__slot > .v-text-field__slot,
.custom-table-styling > .v-table__wrapper .v-input--is-disabled .v-select__slot {
  background: $dbm-light-grey !important;
}

.inactive-dairy {
  color: $dbm-inactive-red;
}

.custom-vertical-scroll-style {
  height: 200px;
  overflow-y: auto;
}

.custom-horizontal-scroll-style {
  overflow-x: hidden;
}
</style>
